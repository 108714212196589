import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Products from "../components/Products";
import SEO from "../components/SEO";

const EnergyPage = () => {
  const data = useStaticQuery(graphql`
    {
      allSitePage(
        filter: { context: { productType: { eq: "energitilskud" } } }
      ) {
        edges {
          node {
            context {
              productTitle
              productImage
              productPath
              productPrice
              productOldprice
              productLink
              productId
            }
          }
        }
      }
    }
  `);
  return (
    <>
      <SEO
        title="Find tilbud på energitilskud til din træning"
        description="Spar en masse penge og find de bedste priser på energidrikke hos os. Det behøver ikke at være dyrt, at komme i form."
      />
      <h1 className="font-bold text-xl">Energitilskud til træning</h1>
      <p className="max-w-xl pb-4">
        Med energitilskud, tilfører du kroppen nogle essentielle aminosyrer til
        opbygning af muskelmassen. Det anbefales at tage energitilskud inden
        træning eller løbeturen, da det er hurtigt optageligt og giver dig et
        boost, der gør du kan yde mere under performance.
      </p>
      <Products data={data} />
      <div className="my-12">
        <h2 className="text-2xl font-bold mb-4">
          Derfor kan energidrikke gavne din træning
        </h2>
        <p className="mb-4">
          Energidrikke har vundet stor popularitet inden for fitness- og
          træningsverdenen. Mange mennesker vælger at indtage energidrikke inden
          deres træning for at opnå ekstra energi og forbedre deres
          præstationer. Mens der er nogle kontroverser omkring brugen af disse
          drikke, er der flere grunde til, hvorfor energidrikke kan være
          gavnlige før en træning.
        </p>

        <p className="mb-4">
          En af de primære fordele ved energidrikke er deres indhold af koffein.
          Koffein er en naturlig stimulant, der kan forbedre den fysiske
          præstation og øge udholdenhed. Ved at indtage en moderat mængde
          koffein før træningen kan man føle sig mere opmærksom, energisk og i
          stand til at yde mere. Koffeinen kan også hjælpe med at reducere
          opfattelsen af anstrengelse, hvilket gør det muligt at træne længere
          og mere intensivt.
        </p>

        <p className="mb-4">
          Ud over koffein indeholder mange energidrikke også kulhydrater.
          Kulhydrater er kroppens primære kilde til energi, især under fysisk
          aktivitet. Ved at indtage en energidrik før træningen kan man øge
          kulhydratindtaget og forsyne kroppen med den nødvendige brændstof til
          at udføre træningen optimalt. Kulhydraterne i energidrikke kan hjælpe
          med at opretholde blodsukkerniveauet og forhindre træthed under
          træningen.
        </p>

        <p className="mb-4">
          Derudover kan energidrikke indeholde forskellige vitaminer og
          mineraler, der er vigtige for kroppens præstation og restitution.
          Mange energidrikke er beriget med B-vitaminer, der spiller en vigtig
          rolle i energiproduktionen og stofskiftet. Disse vitaminer kan hjælpe
          med at omdanne kulhydrater til energi og understøtte den generelle
          træningspræstation.
        </p>

        <p className="mb-4">
          Det er dog vigtigt at bemærke, at energidrikke ikke er nødvendige for
          alle mennesker eller i enhver træningssituation. De bør kun bruges med
          forsigtighed og i moderate mængder. For nogle mennesker kan
          energidrikke forårsage ubehagelige bivirkninger som hjertebanken,
          rysten eller søvnproblemer. Derfor er det vigtigt at kende sin egen
          tolerancetærskel og lytte til kroppens reaktioner.
        </p>
      </div>
    </>
  );
};

export default EnergyPage;
